/*--------------------------------------------------------------
#0.6	about-section
--------------------------------------------------------------*/
.about-section {
	margin-top: 60px;
	padding-bottom: 105px;

	@include media-query(991px) {
		padding-bottom: 95px;
	}

	@include media-query(767px) {
		padding-bottom: 75px;
	}

	@include media-query(991px) {
		margin-top: 0;
	}

	.right-col {
		padding-top: 35px;

		@include media-query(1199px) {
			padding-top: 0;
		}

		@include media-query(991px) {
			margin-top: 40px;
		}

	}

	.right-col>p:first-child:first-letter {
		font-family: $heading-font;
		font-size: 45px;
		font-size: calc-rem-value(45);

		@include media-query(991px) {
			font-size: 35px;
			font-size: calc-rem-value(35);
		}
	}

	.quoter {
		padding: 20px 0;
	}

	.quoter h4 {
		font-size: 18pc;
		font-size: calc-rem-value(18);
		font-weight: 400;
		margin: 0 0 0.3em;

		@include media-query(991px) {
			font-size: 16px;
			font-size: calc-rem-value(16);
		}
	}

	.quoter span {
		font-size: 13px;
		font-size: calc-rem-value(13);
	}
}


/*--------------------------------------------------------------
#0.7	feature-section
--------------------------------------------------------------*/
.feature-section {
	@include media-query(991px) {
		padding-bottom: 85px;
	}

	@include media-query(767px) {
		padding-bottom: 65px;
	}

	padding-top: 0;

	.info-col {
		@include background-style("../../images/feature-info-col-bg.jpg", center center, cover, no-repeat, local);
		padding: 55px 35px;
		position: relative;

		@include media-query(767px) {
			margin-bottom: 15px;
		}

		&:before {
			content: "";
			background: $theme-secondary-color;
			background-image: -webkit-linear-gradient(90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			background-image: -moz-linear-gradient(90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			background-image: -ms-linear-gradient(90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0.95;
		}

		h4,
		a {
			position: relative;
		}

		h4 {
			font-size: 22px;
			font-size: calc-rem-value(22);
			color: $white;
			line-height: 1.5em;
			margin: 0 0 1.5em;
		}
	}

	.feature-grid {
		border: 2px solid #f3f0f0;
		padding: 40px 28px;

		@include media-query(991px) {
			margin-bottom: 15px;
		}

		&:hover {
			background-color: $theme-primary-color;
			// background-image: -webkit-linear-gradient( 90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			// background-image: -moz-linear-gradient( 90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			// background-image: -ms-linear-gradient( 90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			border-color: $theme-primary-color;
			@include transition-time(0.3s);

			h3,
			p,
			.fi:before {
				color: $white;
			}
		}

		.fi:before {
			font-size: 55px;
			color: $theme-primary-color;
		}

		h3 {
			font-size: 20px;
			font-size: calc-rem-value(20);
			font-weight: 400;
			margin: 0em 0 1em;
		}

		p {
			margin: 0;
		}
	}
}


/*--------------------------------------------------------------
#0.8	service-section
--------------------------------------------------------------*/
.service-section {
	@include widther(1200px) {
		margin: 0 80px;
	}

	.content-area {
		display: flex;
		flex-wrap: wrap
	}

	.left-col {
		@include background-style("../../images/ceo-img.jpg", center top, cover, no-repeat, local);
		max-width: 40%;
		padding: 90px;
		float: left;
		position: relative;
		display: table;
		min-height: 100%;

		@include media-query(1500px) {
			padding: 90px 45px;
		}

		@include media-query(1200px) {
			max-width: 100%;
			float: none;
			height: 500px !important;
		}

		@include media-query(767px) {
			padding: 50px 25px;
		}

		&:before {
			content: "";
			background: $theme-secondary-color;
			background: -webkit-linear-gradient(top, transparent, $theme-secondary-color);
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}

		.inner-content {
			display: table-cell;
			vertical-align: bottom;
			position: relative;
		}

		blockquote {
			font-family: $heading-font;
			font-size: 20px;
			font-size: calc-rem-value(20);
			font-weight: 300;
			color: $white;
			border: 0;
			padding: 0;
			margin: 0;
		}

		h4 {
			font-family: $base-font;
			font-size: 16px;
			font-size: calc-rem-value(16);
			font-weight: 700;
			color: $white;
			margin: 1.5em 0 0.3em;
		}

		h4+span {
			font-size: 14px;
			font-size: calc-rem-value(14);
			color: $white;
		}
	}

	.right-col {
		background-color: $theme-secondary-color;
		max-width: 60%;
		float: right;
		padding: 110px 120px 44px 60px;

		@include media-query(1500px) {
			padding: 110px 60px 44px 60px;
		}

		@include media-query(1200px) {
			max-width: 100%;
			float: none;
			border-top: 1px solid transparentize($white, 0.8);
		}

		@include media-query(767px) {
			padding: 80px 25px 24px 25px;
		}
	}

	.service-grids {
		padding-left: 85px;
		margin: 0 -35px;

		@include media-query(1500px) {
			padding-left: 0;
		}

		.grid {
			width: calc(50% - 70px);
			margin: 0 35px 50px;
			float: left;
			position: relative;
			padding-left: 75px;

			@include media-query(600px) {
				width: calc(100% - 70px);
				float: none;
			}
		}

		.fi {
			position: absolute;
			left: 0;
			top: -8px;
		}

		.fi:before {
			font-size: 50px;
			color: $theme-primary-color;
		}

		h3 {
			font-size: 18px;
			font-size: calc-rem-value(18);
			font-weight: 400;
			margin: 0 0 0.5em;

			@include media-query(767px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
			}
		}

		h3 a {
			color: $white;
		}

		h3 a:hover {
			color: $theme-primary-color;
		}

		p {
			color: #c5c5c6;
			margin: 0;
		}
	}
}


/*--------------------------------------------------------------
#0.9	case-studies-section
--------------------------------------------------------------*/
.case-studies-section {
	padding-bottom: 0;

	@include widther(1200px) {
		margin: 0 80px;
	}

	.case-studies-grids .grid {
		position: relative;
		text-align: center;

		.overlay {
			background: $theme-secondary-color;
			background-image: -moz-linear-gradient(90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			background-image: -webkit-linear-gradient(90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			background-image: -ms-linear-gradient(90deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 50%;
			opacity: 0;
			@include transition-time(0.5s);
		}

		img {
			width: 100%;
		}

		&:hover {
			cursor: grab;
		}

		&:hover .overlay {
			top: 0;
			opacity: 0.9;
		}

		.content {
			width: 100%;
			position: absolute;
			left: 0;
			top: 50%;
			@include translatingY();
		}

		.cat {
			font-size: 14px;
			font-size: calc-rem-value(14);
			color: $theme-primary-color;
			font-weight: 700;
			margin: 0;
		}

		h3 {
			font-size: 20px;
			font-size: calc-rem-value(20);
			margin: 0;
		}

		h3 a {
			color: $white;
		}

		h3 a:hover {
			color: $theme-primary-color;
		}
	}
}

.case-studies-pg-section {
	padding-bottom: 95px;

	@include media-query(991px) {
		padding-bottom: 85px;
	}

	@include media-query(767px) {
		padding-bottom: 75px;
	}
}


/*--------------------------------------------------------------
#1.0	testimonials-section
--------------------------------------------------------------*/
.testimonials-section {
	@include background-style("../../images/testimonials/testimonials-bg.png", center center, cover, no-repeat, local);

	.grid .fi:before {
		font-size: 60px;
		color: $theme-primary-color;
	}

	.grid p {
		font-family: $heading-font;
		font-size: 17px;
		font-size: calc-rem-value(17);
		margin: 0;
	}

	.grid img {
		width: auto;
	}

	.testimonials-grids {
		@include widther(1200px) {
			padding-left: 40px;
		}
	}

	.client-info {
		position: relative;
		padding-left: 90px;
		padding-top: 15px;
		min-height: 70px;
		margin-top: 35px;

		.img-holder {
			position: absolute;
			left: 0;
			top: 0;

			img {
				border-radius: 50%;
			}
		}

		h3 {
			font-size: 16px;
			font-size: calc-rem-value(16);
			font-weight: 400;
			margin: 0 0 0.2em;
		}

		h3+span {
			font-size: 14px;
			font-size: calc-rem-value(14);
		}
	}

	.slick-slider {
		margin: -15px;

		.slick-slide {
			padding: 15px;
		}
	}

	.slick-dots {
		@include widther(1200px) {
			position: absolute;
			left: -440px;
			margin: 0;
			bottom: 0;
			text-align: left;
		}

		@include media-query(1199px) {
			margin-top: 45px;
		}
	}

	.slick-dots {
		li {
			width: unset;
			height: unset;

			button {
				width: 8px;
				background: $section-bg-color;
				margin-right: 5px;
				height: 8px;
				padding: 0;
				border-radius: 10px;

				&::before {
					display: none;
				}
			}

			&.slick-active {
				button {
					background: $theme-primary-color;
					width: 20px;
				}
			}
		}
	}
}


/*--------------------------------------------------------------
#1.1	cta-section
--------------------------------------------------------------*/
.cta-section {
	@include background-style("../../images/cta_section.jpg", center center, cover, no-repeat, local);
	padding: 115px 0;
	text-align: center;
	position: relative;
	z-index: 1;

	@include widther(1200px) {
		margin: 0 80px;
	}

	@include media-query(991px) {
		padding: 100px 0;
	}

	@include media-query(767px) {
		padding: 80px 0;
	}

	&:before {
		//content: "";
		//background-image: linear-gradient(180deg, $theme-secondary-color 50%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
		//background-image: -moz-linear-gradient(180deg, $theme-secondary-color 50%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
		//background-image: -webkit-linear-gradient(180deg, $theme-secondary-color 50%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
		//background-image: -ms-linear-gradient(180deg, $theme-secondary-color 50%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
		//width: 100%;
		//height: 100%;
		//position: absolute;
		//left: 0;
		//top: 0;
		//opacity: 0.9;
		//z-index: -1;
	}

	h5,
	h2 {
		color: $white;
	}

	h5 {
		font-size: 18px;
		font-size: calc-rem-value(18);
		margin: 2em 0 0;
	}

	h2 {
		font-size: 36px;
		font-size: calc-rem-value(36);
		margin: 0.5em 0 1em;
	}
}


/*--------------------------------------------------------------
#1.2	team-section
--------------------------------------------------------------*/
.team-section {
	.team-grids {
		position: relative;
		z-index: 11;
	}
	.grid .details {
		padding: 30px 15px;
		text-align: center;
		position: relative;
		border: 1px solid #f1f1f1;

		@include media-query(991px) {
			padding: 25px 15px;
		}
	}

	.grid:hover .social {
		opacity: 1;
		visibility: visible;
	}

	.social {
		width: 100%;
		text-align: center;
		position: absolute;
		top: -17px;
		left: 0;
		opacity: 0;
		visibility: hidden;
		@include transition-time(0.5s);

		ul {
			background: #d8dbe0;
			padding: 5px 15px;
			display: inline-block;
			overflow: hidden;
			border-radius: 35px;
		}

		li {
			float: left;
		}

		ul>li+li {
			margin-left: 15px;
		}

		a {
			font-size: 14px;
			font-size: calc-rem-value(14);
			color: $theme-secondary-color;
		}

		a:hover {
			color: $theme-primary-color;
		}
	}

	.details {
		background: $white;

		h3 {
			font-size: 18px;
			font-size: calc-rem-value(18);
			font-weight: 400;
			margin: 0 0 0.4em;

			@include media-query(991px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
			}
		}

		h3 a {
			color: $heading-color;
		}

		h3 a:hover {
			color: $theme-primary-color;
		}

		p {
			font-size: 13px;
			font-size: calc-rem-value(13);
			margin: 0;
		}
	}

	// slider controls
	.slick-prev,
	.slick-next {
		top: 40%;
		background-color: $white;
		width: 55px;
		height: 55px;
		line-height: 55px;
		z-index: 10;
		@include rounded-border(50%);
		transition: all .3s;
		border-radius: 50%;
		-webkit-box-shadow: 0px 9px 42.32px 3.68px rgba(25, 38, 62, 0.15);
		box-shadow: 0px 9px 42.32px 3.68px rgba(25, 38, 62, 0.15);

		&:hover {
			background-color: $theme-primary-color;
		}

		&:before {
			color: $black;
			line-height: unset;
		}

		&:hover {
			background-color: $theme-primary-color;

			&:before {
				color: $white
			}
		}

	}

	.slick-prev {
		left: -27px;

		@include media-query(767px) {
			display: none !important;
		}

		&:before {
			font-family: "themify";
			content: "\e629";
			opacity: 1;
		}
	}

	.slick-next {
		right: -27px;

		@include media-query(767px) {
			display: none !important;
		}

		&:before {
			font-family: "themify";
			content: "\e628";
			opacity: 1;
		}
	}
}


/*--------------------------------------------------------------
#1.3	contact-section
--------------------------------------------------------------*/
.contact-section {
	background: $theme-secondary-color;
	@include background-style("../../images/contact-bg.jpg", center center, cover, no-repeat, local);
	padding: 220px 0 35px;
	position: relative;

	&:before {
		content: "";
		background: linear-gradient(1deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
		background-image: -moz-linear-gradient(1deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
		background-image: -webkit-linear-gradient(1deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
		background-image: -ms-linear-gradient(1deg, $theme-secondary-color 62%, $theme-primary-color 96%, $theme-primary-color 97%, $theme-primary-color 100%);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0.97;
	}

	@include widther(1450px) {
		margin: -219px 80px 0;
	}

	@include media-query(1449px) {
		padding: 110px 0 35px;
	}

	@include media-query(991px) {
		padding: 100px 0 25px;
	}

	@include media-query(767px) {
		padding: 80px 0 25px;
	}

	.contact-info-col {
		width: calc(50% - 85px);
		float: left;
		padding-top: 0px;

		@include media-query(991px) {
			width: 100%;
			float: none;
			padding-top: 0;
		}

		.contact-info {
			width: 500px;
			float: right;

			@include media-query(1199px) {
				padding-left: 100px;
			}

			@include media-query(991px) {
				width: 100%;
				float: none;
				padding-left: 15px;
				margin-bottom: 80px;
			}
		}
	}

	.contact-form-col {
		width: calc(50% + 85px);
		float: right;
		padding-right: 160px;
		position: relative;

		@include media-query(1450px) {
			padding-right: 60px;
		}

		@include media-query(991px) {
			width: 100%;
			float: none;
			padding: 0 15px;
		}
	}

	.contact-info {
		li {
			position: relative;
			padding-left: 85px;
		}

		ul>li+li {
			margin-top: 50px;
		}

		.fi {
			position: absolute;
			left: 0;
			top: -10px;
		}

		.fi:before {
			font-size: 50px;
			color: $theme-primary-color;
		}

		h4 {
			font-size: 18px;
			font-size: calc-rem-value(18);
			font-weight: 400;
			color: $white;
			margin: 0 0 0.2em;
		}

		p {
			font-size: 16px;
			font-size: calc-rem-value(16);
			color: $white;
			margin: 0;
		}
	}

	.contact-form {
		padding-left: 87px;

		@include media-query(1199px) {
			padding-left: 0;
		}
	}

	form input,
	form select,
	form textarea {
		background: $theme-secondary-color;
		width: 100%;
		height: 55px;
		border: 2px solid transparentize($white, 0.8);
		border-radius: 0;
		box-shadow: none !important;

		@include media-query(991px) {
			height: 45px;
		}

		&:focus {
			border-color: $theme-primary-color;
			background: $theme-secondary-color;
		}
	}

	form textarea {
		height: 180px;
	}

	.errorMessage {
		color: red;
		margin-top: 5px;
	}

	form {
		margin: 0 -15px;
		overflow: hidden;

		@include placeholder-style(#9d9c9c, 15px, normal);

		select {
			display: inline-block;
			color: #a9a9a9;
			cursor: pointer;
			opacity: 1;
			padding: 6px 12px;
			font-size: 15px;
			font-size: calc-rem-value(15);
			appearance: auto;
			
			&:focus {
				border-color: $theme-primary-color;
				color: $white;
			}

			option {
				color: $white;
			}
		}
	}

	form>div {
		width: calc(50% - 30px);
		float: left;
		margin: 0 15px 30px;

		@include media-query(600px) {
			width: calc(100% - 30px);
			float: none;
		}
	}

	form .fullwidth {
		width: calc(100% - 30px);
		float: none;
		clear: both;
	}
}


/*--------------------------------------------------------------
#1.4	blog-section
--------------------------------------------------------------*/
.blog-section {
	padding-bottom: 70px;

	@include media-query(1199px) {
		padding-bottom: 60px;
	}

	@include media-query(991px) {
		padding-bottom: 50px;
	}

	@include media-query(767px) {
		padding-bottom: 40px;
	}

	.blog-grids {
		margin: 0 -15px;

		@include media-query(1199px) {
			margin-top: 50px;
		}

		@include media-query(767px) {
			margin: 50px -7.5px 0;
		}

		.grid {
			width: calc(50% - 30px);
			float: left;
			margin: 0 15px 50px;

			@include media-query(767px) {
				width: calc(50% - 15px);
				margin: 0 7.5px 50px;
			}

			@include media-query(600px) {
				width: calc(100% - 15px);
				float: none;
			}
		}
	}

	.entry-details {
		position: relative;
		padding: 45px 0 0 20px;

		h3 {
			font-size: 21px;
			font-size: calc-rem-value(21);
			font-weight: 400;
			margin: 0 0 0.9em;
			line-height: 1.3em;

			@include media-query(991px) {
				font-size: 18px;
				font-size: calc-rem-value(18);
			}
		}

		h3 a {
			color: $heading-color;

			&:hover {
				color: $theme-primary-color;
			}
		}

		.cat {
			background: $theme-primary-color;
			font-size: 13px;
			font-weight: 700;
			padding: 10px 15px;
			color: white;
			position: absolute;
			left: 0;
			top: -20px;
			border-radius: 0 50px 50px 0;
		}

		.read-more {
			font-family: $heading-font;
			color: $text-color;
			padding-left: 50px;
			position: relative;

			@include media-query(991px) {
				font-size: 14px;
				font-size: calc-rem-value(14);
			}

			&:before {
				content: "";
				background: $text-color;
				width: 40px;
				height: 2px;
				position: absolute;
				left: 0;
				top: 11px;
				@include transition-time(0.3s);
			}

			&:hover {
				color: $theme-primary-color;
			}

			&:hover:before {
				background: $theme-primary-color;
			}
		}
	}
}